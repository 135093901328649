import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import { StickyCards } from "@/components/StickyCards";
import { Button } from "@/components/Button";
import { Carousel } from "@/components/Carousel";
import { TestimonialCard } from "@/components/TestimonialCard";
import { GlobalPaymentsQuoter } from "@/components/GlobalPaymentsQuoter";

import { ArrowRightEndOnRectangleIcon } from "@heroicons/react/24/outline";
import WhatsappIcon from "@/assets/Icons/whatsapp.svg?react";

import headerMock from "@/assets/Img/header-mockup.png";
import AnthonyPhoto from "@/assets/Img/Anthony.png";
import MarianaPhoto from "@/assets/Img/Mariana.png";
import CountriesEsGif from "@/assets/Gifs/countries_es.gif";
import CountriesEnGif from "@/assets/Gifs/countries_en.gif";

const AuthUrl = import.meta.env.VITE_AUTH_DOMAIN;

export const HomePage = () => {
  const [t] = useTranslation("global");
  const { i18n } = useTranslation();
  const lang = i18n.language;

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (window.location.hash) {
      const id = window.location.hash.replace("#", "");
      const element = document.getElementById(id);

      if (element) element.scrollIntoView();
    }
  }, []);

  return (
    <div className="w-full">
      <Helmet>
        <title>Sendola | Inicio</title>
      </Helmet>

      <div className="flex flex-col">
        <div className="flex justify-between lg:justify-center gap-2 bg-gradient-to-t from-[#5400C8] from-55% to-[#6E66FF] h-40 sm:h-52 md:h-72 lg:h-[300px] rounded-b-2xl overflow-hidden">
          <div className="flex w-7/12 md:w-1/2 flex-col justify-between text-white py-2 sm:py-4 lg:py-10 pl-6 lg:pl-32">
            <h1 className="text-base md:text-2xl align-middle min-w-56 text-wrap max-w-xl">
              <span
                dangerouslySetInnerHTML={{
                  __html: t("Home.SendMoney"),
                }}
              />
            </h1>
          </div>

          <div className="w-5/12 md:w-1/2 flex justify-center relative overflow-hidden lg:pr-32">
            <img
              className="h-full w-full object-contain absolute"
              src={headerMock}
              id="sendMoney"
            />
          </div>
        </div>
      </div>

      {/* Quoter */}
      <div className="w-full flex flex-col items-center px-6 lg:px-10 py-2 gap-4">
        <div className="flex flex-col gap-2 w-full py-2 -mb-2 max-w-screen-sm 2xl:max-w-screen-md">
          <div className="flex items-center">
            <h2 className="lg:text-2xl text-xl font-semibold text-purple-700 mr-2.5">
              {t("Home.ScrollCallout")}
            </h2>
            <img
              className="h-20 sm:h-32 lg:h-44 -my-6 sm:-my-10 inline object-contain max-w-36"
              src={lang === "es" ? CountriesEsGif : CountriesEnGif}
              alt="gif countries"
            />
          </div>
          <GlobalPaymentsQuoter />
        </div>
      </div>

      <div
        className="flex flex-col items-center gap-4 w-full p-6 pb-0"
        id="signIn"
      >
        <div className="flex flex-col gap-4 items-center w-full max-w-screen-sm 2xl:max-w-screen-md">
          <div className="flex flex-col gap-2 w-full">
            <h1 className="text-xl text-purple-600 font-semibold leading-none">
              {t("Home.LoginTitle")}
            </h1>
          </div>
          <div className="inline-flex justify-around w-full gap-4 lg:gap-6">
            <Button
              className="shadow-xl py-4 min-w-40 w-full"
              text={t("Home.LoginButton")}
              link={`${AuthUrl}/sign-in`}
            />

            {/* <div className="flex gap-4 h-fit items-center w-full">
              <div className="w-full border-b-2 border-gray-400" />
              <span className="text-gray-500 font-semibold">{t("Home.Or")}</span>
              <div className="w-full border-b-2 border-gray-400" />
            </div> */}

            <Button
              className="py-4 min-w-40 w-full"
              text={t("Home.SignupButton")}
              variant="secondary"
              onClick={() => setOpenModal(true)}
            />
          </div>
        </div>
      </div>

      <div id="cards" className="flex flex-col items-center p-6">
        <StickyCards />
      </div>

      {/* <div id="benefits" className="flex justify-center px-6 lg:px-10 mb-10">
        <div className="flex flex-col gap-4 max-w-screen-sm 2xl:max-w-screen-md w-full">
          <img
            className="h-16 w-full object-contain object-center mt-4"
            src={lang === "es" ? Benefits : BenefitsEn}
            alt={t("Home.Section3.LogoAlt")}
          />
          <div className="flex flex-col gap-4 w-full">
            <div className="w-full">
              <p
                className="text-sm text-purple-800 lg:text-base"
                dangerouslySetInnerHTML={{
                  __html: t("Home.Section3.Subtitle"),
                }}
              />
            </div>

            <Card className="relative w-full" noPadding>
              <div className="w-full flex flex-col gap-4 py-4">
                <BenefitItem
                  title={t("Home.Section3.Bullet1.Title")}
                  paragraph={t("Home.Section3.Bullet1.Paragraph")}
                  buttonText={t("Home.Section3.Bullet1.Button")}
                  link="https://sendola.us/adelanto"
                  imgTitle={
                    <img
                      className={`lg:h-12 w-auto ${lang === "es" ? "h-9 lg:w-[80%]" : "h-8"}`}
                      src={lang === "es" ? Adelanto : Advance}
                      alt={t("Home.Section3.Bullet1.Button")}
                    />
                  }
                  imgBody={AdelantoBodyImg}
                  bgCard="bg-gradient-to-t !from-emerald-500 !to-stone-900"
                />
                <BenefitItem
                  title={t("Home.Section3.Bullet3.Title")}
                  paragraph={t("Home.Section3.Bullet3.Paragraph")}
                  buttonText={t("Home.Section3.Bullet3.Button")}
                  link="https://sendola.us/repatriacion"
                  imgTitle={
                    <img
                      className={`lg:h-12 ${lang === "es" ? "h-9 w-[90%] lg:w-[67%]" : "h-8 w-[100%]"}`}
                      src={lang === "es" ? Contigo : Repatriation}
                      alt={t("Home.Section3.Bullet3.Button")}
                    />
                  }
                  imgBody={ContigoBodyImg}
                  bgCard="bg-gradient-to-t !from-purple-900 !to-stone-900"
                />
              </div>
            </Card>
          </div>
        </div>
      </div> */}

      <div
        id="testimonials"
        className="w-full flex justify-center px-6 lg:px-10 pb-10"
      >
        <div className="flex flex-col gap-4 w-full max-w-screen-sm 2xl:max-w-screen-md">
          <div className="w-full md:h-full md:w-5/12">
            <p className="text-sm text-purple-800 font-semibold lg:text-base">
              {t("Home.Section4.Title")}
            </p>
          </div>

          <Carousel
            items={[
              {
                id: "1",
                content: (
                  <TestimonialCard
                    title={t("Home.Testimonial1.Name")}
                    subtitle={t("Home.Testimonial1.Ocupation")}
                    paragraph={t("Home.Testimonial1.Content")}
                    imageUrl={AnthonyPhoto}
                  />
                ),
              },
              {
                id: "2",
                content: (
                  <TestimonialCard
                    title={t("Home.Testimonial2.Name")}
                    subtitle={t("Home.Testimonial2.Ocupation")}
                    paragraph={t("Home.Testimonial2.Content")}
                    imageUrl={MarianaPhoto}
                  />
                ),
              },
            ]}
          />
        </div>
      </div>

      <div
        id="hs-basic-modal"
        className={`hs-overlay hs-overlay-open:opacity-100 hs-overlay-open:duration-500 fixed top-0 start-0 z-[80] w-full h-full opacity-0 overflow-x-hidden transition-all overflow-y-auto pointer-events-none ${
          openModal ? "opacity-100 pointer-events-auto" : "hidden"
        }`}
        role="dialog"
        tabIndex={-1}
        aria-labelledby="hs-basic-modal-label"
        style={{ background: "rgba(0, 0, 0, 0.70)" }}
      >
        <div className="sm:max-w-lg sm:w-full m-3 sm:mx-auto">
          <div className="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:border-neutral-700 dark:shadow-neutral-700/70 p-6">
            <div className="flex justify-between md:justify-center items-center mb-4">
              <h3 id="hs-basic-modal-label" className="font-semibold">
                {t("Home.Modal.title")}
              </h3>
            </div>
            <div className="mx-auto w-full text-center">
              <button
                className="flex shadow-xl items-center mx-auto justify-center font-semibold py-2 px-10 bg-indigo-500 border-2 border-indigo-500 text-white rounded-full border-box hover:opacity-90 w-fit text-base mb-4"
                onClick={() => window.open(`${AuthUrl}/sign-up`)}
              >
                <ArrowRightEndOnRectangleIcon className="w-6 h-6 mr-2" />
                {t("Home.Modal.singUpButton")}
              </button>
              <div className="mb-4 flex">
                <span className="border-y w-full h-0 slate-200 my-auto" />
                <span className="mx-2 text-gray-500 text-sm">
                  {t("Home.Modal.or")}
                </span>
                <span className="border-y w-full h-0 slate-200 my-auto" />
              </div>
              <div className="mb-4 text-center">
                <a
                  href={`https://api.whatsapp.com/send?phone=18325251941&text=${t("Home.Modal.hi")}`}
                  rel="Sing up"
                  target="_blank"
                  className="flex text-indigo-500 text-xs font-semibold text-center justify-center cursor-pointer"
                >
                  <WhatsappIcon className="w-4 h-4 mr-2" />
                  {t("Home.Modal.whatsappButton")}
                </a>
              </div>
              <button
                className="text-gray-400"
                onClick={() => setOpenModal(false)}
              >
                {t("Home.Modal.back")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
